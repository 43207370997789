import { NgModule } from "@angular/core";
import {
    Routes, RouterModule
} from "@angular/router";


const routes: Routes = [
    {
        path: "**",
        redirectTo: "/login"
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {}
